<template>
  <div class="authentication-form">
    <div class="container">
      <h1
        v-if="title"
        class="authentication-form__title"
      >
        {{ title }}
      </h1>
      <van-form
        validate-first
        class="authentication-form__form"
        @submit="submitForm"
      >
        <field-list
          dark
          class="authentication-form__fields"
        >
          <slot />
        </field-list>
        <van-button
          type="white"
          native-type="submit"
          :text="submitText || $t('submit')"
        />
      </van-form>
    </div>
    <div
      v-if="$slots.footer"
      class="authentication-form__footer"
    >
      <div class="container">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import FieldList from '@/components/FieldList';

export default {
  components: {
    FieldList,
  },
  props: {
    title: { type: String, default: '' },
    submitText: { type: String, default: '' },
  },
  methods: {
    // Parse the values out of the component.
    submitForm(values) {
      this.$emit('submit', values);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '~@/styles/config' as config;

.authentication-form {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}

.authentication-form__title {
  padding: 2.1875rem 0 1.75rem;
  margin: 0;
  font-size: 1.3125rem;
  font-weight: bold;
  letter-spacing: 0.01285em;
  line-height: 1.2;
  text-align: center;
}

.authentication-form__form,
.authentication-form__fields {
  margin-bottom: config.$spacing;
}

.authentication-form__footer {
  padding: 0 0 config.$spacing-lg;
  margin-top: auto;

  .van-button + .van-button {
    margin-top: config.$spacing;
  }
}
</style>
