<template>
  <authentication-form
    :title="$t('auth.reset.title')"
    :submit-text="$t('auth.reset.button')"
    class="reset"
    @submit="submitForm"
  >
    <van-field
      v-model="email"
      reference="email"
      name="email"
      type="email"
      label="Email"
      tabindex="1"
      :rules="[
        { required: true, message: $t('auth.validation.email.required') },
        { validator: emailValidator, message: $t('auth.validation.email.invalid') },
      ]"
      :error="!!emailError || email.validateFailed"
      :error-message="emailError"
    />
    <template #footer>
      <van-button
        type="hollow-white"
        :to="{ name: 'login' }"
        :text="$t('back')"
      />
    </template>
  </authentication-form>
</template>

<script>
import { Notify } from 'vant';
import { auth } from '@/firebase';
import { emailValidator } from '@/validators';
import AuthenticationForm from '@/components/AuthenticationForm/AuthenticationForm.vue';

export default {
  components: {
    AuthenticationForm,
  },
  data() {
    return {
      email: '',
      emailError: '',
    };
  },
  methods: {
    emailValidator(value) {
      return emailValidator(value);
    },
    submitForm(values) {
      auth.sendPasswordResetEmail(values.email)
        .then(() => {
          Notify({
            type: 'success',
            className: 'auth-notification',
            message: this.$i18n.t('auth.reset.confirmation'),
            duration: 5000,
          });
          this.email = '';
        })
        .catch((error) => {
          if (error.code === 'auth/user-not-found') {
            this.emailError = error.message;
          }
        });
    },
  },
};
</script>
